<template>
  <div class="wallet-card">
    <div class="balance-card-wrap is-relative">
      <div class="shadow-box"></div>
      <div class="balance-card">
        <slot></slot>
        <div class="switch-wallet is-flex is-justify-content-center is-align-items-center is-clickable" @click="switchWallet">
          <span class="icon">
            <i class="fas fa-exchange-alt has-text-blue"></i>
          </span>
        </div>
        <img src="@assets/images/dashboard/addy.png" alt="addy" class="addy">
        <div class="card-title">
          <span :class="`has-text-white ${onWalletPage ? '' : 'is-clickable'}`" @click="toWallet">Wallet Balance</span>
        </div>
        <div>
          <div v-if="data.isLoading" class="mt-3">
            <b-skeleton width="40%" height="30px" position="is-left"></b-skeleton>
          </div>
          <div v-else @click="toWallet" :class="`is-inline-block ${onWalletPage ? '' : 'is-clickable'}`">
            <span class="currency-icon has-text-weight-semibold has-text-white">$</span>
            <span class="balance has-text-white">{{truncatedBalance}}</span>
            <span class="currency-icon unit has-text-weight-semibold has-text-white">{{data.walletCurrency}}</span>
          </div>
        </div>
        <div class="is-flex bottom-section is-justify-content-space-between is-align-items-center">
          <div class="addy-handle" v-if="ownable === 'addy Personal'">
            <span class="has-text-white" v-if="data.addyHandle">{{data.addyHandle}}</span>
            <span class="has-text-white" v-else>{{data.firstName}} {{data.lastName}}</span>
          </div>
          <div v-if="data.isLoading">
            <b-skeleton width="20px" height="20px" position="is-right"></b-skeleton>
          </div>
          <div class="is-relative" v-else-if="data.processingDepositTotal">
            <img src="@assets/icons/pending.svg" alt="pending" class="processing is-clickable" @click.stop="toggleTooltip">
            <div class="tooltip" v-show="showProcessingTooltip">
              <div class="has-text-white has-text-centered amount">{{data.processingDepositTotal | currencyFormatter}}</div>
              <div class="has-text-white has-text-centered">processing</div>
              <div class="triangle"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { formatter, truncate } from '@utils/common-methods/numberFormatter'

export default {
  data() {
    return {
      showProcessingTooltip: true,
      tooltipTimer: null,
    }
  },
  computed: {
    truncatedBalance() {
      return formatter(truncate(this.data.walletBalance, 2))
    },
    onWalletPage() {
      return ['wallet', 'addyPlusCorporationWallet'].includes(this.$route.name)
    },
    accountId() {
      return this.$route.params.accountId || ''
    },
    corporationId() {
      return this.$route.params.corporationId || ''
    },
    ownedByAddyBusiness() {
      return this.$route.path.startsWith('/addyPlus')
    },
    walletIndexPage() {
      return this.ownedByAddyBusiness ? `/addyPlus/${this.accountId}/corporations/${this.corporationId}/wallet` : '/wallet'
    },
  },
  filters: {
    currencyFormatter(value) {
      const number = Number(value)
      return number.toLocaleString('en-CA',
        { style: 'currency', currency: 'CAD' }
      )
    },
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    ownable: {
      type: String,
      default: 'addy Personal',
      validator(value) {
        return ['addy Personal', 'addy Business'].includes(value)
      },
    },
  },
  methods: {
    toggleTooltip() {
      this.showProcessingTooltip = !this.showProcessingTooltip
      if (this.showProcessingTooltip) {
        if (this.tooltipTimer) clearTimeout(this.tooltipTimer)
        this.tooltipTimer = setTimeout(() => {
          this.showProcessingTooltip = false
        }, 4000)
      }
    },
    toWallet() {
      if (this.onWalletPage) return
      this.$router.push(this.walletIndexPage)
    },
    switchWallet() {
      if (this.data.isLoading) return
      this.$emit('switchWallet')
    },
  },
}
</script>
<style lang="scss" scoped>
.wallet-card {
  padding: 0 25px 25px;
  .balance-card-wrap {
    height: 185px;
    .balance-card {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 185px;
      border-radius: 12px;
      background: url('../../assets/images/dashboard/wallet-card.png') center/cover no-repeat;
      padding-top: 17px;
      padding-left: 23px;
      .addy {
        width: 25px;
        margin-bottom: 10px;
      }
      .switch-wallet {
        width: 32px;
        height: 32px;
        position: absolute;
        top: 8px;
        right: 9px;
        border-radius: 6px;
        background: rgba(255, 255, 255, 0.5);
      }
      .card-title {
        font-size: 20px;
      }
      .currency-icon {
        font-size: 20px;
        vertical-align: top;
        line-height: 33px;
      }
      .balance {
        font-size: 40px;
        padding-left: 6px;
        padding-right: 6px;
      }
      .bottom-section {
        padding-top: 34px;
        padding-right: 18px;
        .addy-handle {
          font-size: 16px;
        }
        .processing {
          margin-top: 6px;
          width: 15px;
        }
        .tooltip {
          width: 100px;
          height: 60px;
          background: rgba(255, 255, 255, 0.5);
          border-radius: 8px;
          position: absolute;
          right: -8px;
          bottom: 30px;
          > div {
            font-size: 16px;
          }
          .amount {
            margin-top: 10px;
            margin-bottom: 4px;
          }
          .triangle {
            width: 0;
            height: 0;
            border: 4px solid transparent;
            border-top-color: rgba(255, 255, 255, 0.5);
            border-top-width: 6px;
            position: absolute;
            right: 12px;
            bottom: -10px;
          }
        }
      }
    }
    .shadow-box {
      position: absolute;
      bottom: 2px;
      left: 52px;
      width: 223px;
      height: 40px;
      box-shadow: 0px 4px 50px rgba(215, 145, 51, 0.25);
    }
  }
}
</style>
