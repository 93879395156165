















import Vue from 'vue'
import Breadcrumb from '@components/breadcrumb/breadcrumb.vue'
import WalletCard from '@views/dashboard/WalletCard.vue'
// @ts-ignore
import corpDetailsMixin from '@utils/mixins/addy-plus-corporation-details-from-side-menu-mixin.js'
import { mapState } from 'vuex'
import { fetchWalletDetails, fetchConnectedAccounts } from '@api/addy-plus/wallet'
import { IMethods, IComputed } from '@/types/addy_plus/corporations/wallet/FundsBase'

export default Vue.extend<unknown, IMethods, IComputed>({
  mixins: [corpDetailsMixin],
  components: {
    Breadcrumb,
    WalletCard,
  },
  computed: {
    ...mapState('addPlusEntityFunds', ['walletBalance', 'processingDepositTotal']),
    pageTitle(): string {
      return this.$route.meta.breadcrumb
    },
    walletCardData() {
      return {
        walletBalance: this.walletBalance,
        processingDepositTotal: this.processingDepositTotal,
      }
    },
    corporationId(): string {
      return this.$route.params.corporationId
    },
  },
  created() {
    this.fetchBankAccounts()
    this.fetchWalletBalance()
  },
  methods: {
    fetchBankAccounts(): void {
      fetchConnectedAccounts(this.corporationId).then((response) => {
        if (!response.success) return
        this.$store.commit('addPlusEntityFunds/setConnectedAccounts', response.data.accountList)
      })
    },
    fetchWalletBalance(): void {
      fetchWalletDetails(this.corporationId).then((res) => {
        if (!res.success) return
        const { pendingCoinOrderAmount, walletBalance } = res.data
        this.$store.commit('addPlusEntityFunds/setWalletBalance', walletBalance as number)
        this.$store.commit('addPlusEntityFunds/setPendingFunds', pendingCoinOrderAmount as number)
      })
    },
  },
})
